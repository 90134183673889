.map-legend {
    color: #656565;
    font-size: 14px;
    font-family: 'SegoeUI';
    opacity: 0.8;
    cursor: grab;

    .card-header {
        padding: 1rem;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .close-button {
            cursor: pointer;
        }

        label {
            margin: 0;
        }
    }

    .legend-body {
        padding: 0.5rem 1rem;

        .legend-item {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;

            .item-bullet {
                height: 10px;
                width: 10px;
                border-radius: 5px;
            }
        }
    }
}
