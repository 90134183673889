.public-profile {
    .public-profile-header {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
        text-transform: none;
        padding-left: 17px;
    }

    .remove-image-container
    {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5px;
        .delete-button {
            color: #d32f2f;
            cursor: pointer;
        }
    }



    .description-form {
        padding: 15px 17px;
    }

    .card-body-custom {
        padding: 20px;
    }

    .input-without-label {
        display: flex;
    }

    .upload-image-wrapper {
        display: flex;
        gap: 30px;
        max-height: 100px;
    }

    .image-container {
        display: flex;
        gap: 30px;

        .preview-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 100px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .label-container {
            min-height: 50px;
        }

        .upload-image-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 20px;

            .upload-box .dropzone {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #ccc;
                padding: 20px;
                border-radius: 8px;
                width: 180px;
                height: 100px;
                background: #f9f9f9;
                gap: 10px;
            }

            .upload-label {
                margin-top: 10px;
                font-size: 14px;
                color: #555;
                text-align: center;
            }
        }
    }

    .section-label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .form-group {
        margin-bottom: 20px;
    }

    label {
        display: block;
        font-size: 14px;
        font-family: 'SegoeUI-SemiBold';
        margin-bottom: 10px;
    }

    .rodal {
        h5 {
            color: black;
            margin: 0;
        }
    }

    .row {
        margin-bottom: 15px;
    }

    .public-uploads-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        gap: 17px;
    }

    .space-between {
        justify-content: space-between;
    }
    .top-aligned {
        align-items: flex-start !important;
    }

    .multiselect {
        input {
            border: 0 !important;
            height: 0px !important;
            padding: 0px 10px;
        }

        .rw-multiselect-input {
            display: block;
        }

        .rw-multiselect-input::placeholder {
            color: #888f96;
        }

        .rw-widget-container {
            padding-top: 5px;
        }

        .rw-multiselect-tag {
            width: fit-content;
            display: inline-table;
            color: #888f96;
            height: 20px;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold' !important;
            border-radius: 4px;
            background-color: #e5e8eb;
            border: 1px solid #e5e8eb;
            margin-left: 5px;
            margin-bottom: 5px;
            margin-top: 0;
            min-height: 0;
        }

        .rw-select {
            vertical-align: middle;
            margin-bottom: 20px;
        }

        .rw-multiselect {
            cursor: default;
        }

        .rw-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container {
            height: 100% !important;
            max-height: 150px;
            overflow-y: auto;
        }

        .rw-multiselect-tag-btn {
            padding: 0;
            margin-right: 5px;
            vertical-align: top;
        }

        .rw-multiselect-tag-label {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }

    input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 14px;
    }

    select {
        padding: 0px 5px;
    }

    .public-uploads {
        margin-top: 30px;

        .upload-btn {
            margin-bottom: 10px;
        }

        .upload-note {
            font-size: 12px;
            color: #777;
            margin-top: 5px;
        }

        .uploaded-files {
            display: flex;
            gap: 15px;
            margin-top: 10px;
        }

        .file-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background: #f9f9f9;
            font-size: 14px;
        }

        .file-icon {
            font-size: 16px;
            color: #d9534f;
        }
    }

    .form-control:focus {
        border-color: #545cd8 !important;
        box-shadow: none;
    }
}
