.landscape-map {
    .leaflet-control-attribution {
        display: none !important;
    }

    .existing-practice-visibility-switcher {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: 'Segoe UI';
        font-size: 14px;
    }
}
